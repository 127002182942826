<template>
  <div>
    <div v-if="currentImage" style="margin-bottom: 40px;">
      <label for="description" class="el-form-item__label">{{
        currentLabel
      }}</label>
      <div class="previewContainer">
        <el-image :src="currentImage" style="width: 80%;"></el-image>
      </div>
    </div>
    <label for="description" class="el-form-item__label">{{ label }}</label>
    <file-pond
      label-idle="Datei hierhin ziehen oder klicken..."
      allow-multiple="false"
      :accepted-file-types="allowedFileTypes"
      :files="chosenImage"
      max-files="1"
      style="margin-bottom: 40px;"
      allow-paste="false"
      @addfile="addFile"
    />
  </div>
</template>

<script>
// Import FilePond
import vueFilePond from "vue-filepond"

// Import plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"

// Import styles
import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css"

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
)

export default {
  components: {
    FilePond
  },
  props: {
    label: {
      type: String,
      required: true
    },
    currentLabel: {
      type: String,
      required: true
    },
    currentImage: {
      type: String,
      default: null
    },
    allowedFileTypes: {
      type: String,
      default: "image/jpeg, image/png, image/svg+xml"
    }
  },
  data() {
    return {
      chosenImage: null
    }
  },
  computed: {},
  created() {},
  methods: {
    addFile(error, data) {
      this.$emit("file-chosen", data.file)
    }
  }
}
</script>

<style lang="scss" scoped>
.previewContainer {
  background-color: #eee;
  border: 2px dashed #ccc;
  padding: 16px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
}
</style>
